<template>
  <div>
    <BlockUI :blocked="blocked" :fullScreen="true">
      <h2>Setting</h2>
      <Card>
        <template #content>
          <h3>Account</h3>
          <div class="card-container">
            <div class="field grid">
              <label class="lg:col-1 md:col-2 sm:col-4" for="my-email">
                Email
              </label>
              <div class="col-6">
                <InputText
                  v-model="myEmail"
                  type="text"
                  placeholder="email"
                  style="width: 100%"
                  id="my-email"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="field grid">
              <label class="lg:col-1 md:col-2 sm:col-4" for="my-name">
                Name
              </label>
              <div class="col-6">
                <InputText
                  v-model="myName"
                  type="text"
                  placeholder="name"
                  style="width: 100%"
                  id="my-name"
                />
              </div>
              <Button
                label="update"
                class="p-button-sm p-button-text"
                @click="updateInfo"
              />
            </div>
          </div>
          <h3>Teams</h3>
          <div class="card-container">
            <div class="pb-1">You are impersonate as team</div>
            <div class="field grid">
              <label class="lg:col-1 md:col-2 sm:col-4" for="my-impersonate">
                Imitate
              </label>
              <div class="col-6">
                <KBaseDropdown
                  v-model="selectedTeamUuid"
                  :options="optionTeams"
                  @change="updateTeam"
                />
              </div>
            </div>
            <div class="field grid">
              <label class="lg:col-1 md:col-2 sm:col-4" for="my-teamname">
                Name
              </label>
              <div class="col-6">
                <InputText
                  v-model="ownTeamName"
                  type="text"
                  placeholder="name"
                  style="width: 100%"
                  id="my-teamname"
                />
              </div>
              <Button
                label="update"
                class="p-button-sm p-button-text"
                @click="updateOwnTeam"
              />
            </div>
          </div>
          <h5>Your team ({{ ownTeam?.name }}) members</h5>
          <DataTable
            :value="dataSource"
            dataKey="email"
            responsiveLayout="scroll"
          >
            <template #header>
              <div class="table-header">
                <Button @click="handleShowInviteDialog">Invite</Button>
              </div>
            </template>
            <Column field="name" header="Name"></Column>
            <Column field="email" header="Email"></Column>
            <Column header="Action" columnKey="action">
              <template #body="{ index, data }">
                <div>
                  <!--
                    Default the first member is the team owner, we can not remove the owner from team
                  -->
                  <Button
                    @click="handleRemoveMember(data)"
                    class="p-button-rounded p-button-text"
                    v-if="index > 0"
                  >
                    Remove
                  </Button>
                </div>
              </template>
            </Column>
          </DataTable>
        </template>
      </Card>
    </BlockUI>
    <Dialog
      header="Invite user to your team"
      v-model:visible="showInviteDialog"
    >
      <InputText
        v-model="invitedEmail"
        type="text"
        placeholder="email"
        style="width: 100%"
      />
      <template #footer>
        <Button
          label="Cancel"
          icon="pi pi-times"
          class="p-button-text"
          @click="handleHideInviteDialog"
        />
        <Button
          label="Send Invite"
          icon="pi pi-check"
          @click="sendInvitation"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Card from "primevue/card";

import KBaseDropdown from "@/components//KBaseDropDown";

import store from "@/store";
import TeamService from "@/services/team_service";
import UserInfoService from "@/services/user_info_service";
import UserOwnTeamService from "@/services/user_own_team_service";

export default defineComponent({
  components: {
    BlockUI,
    Button,
    Column,
    DataTable,
    Dialog,
    InputText,
    Card,

    KBaseDropdown,
  },

  setup() {
    onMounted(() => {
      myName.value = store.getters.userName;
      loadTeams();
    });

    const confirm = useConfirm();
    const toast = useToast();

    const myName = ref("");
    const blocked = ref(true);
    const ownTeam = ref();
    const ownTeamName = ref("");
    const dataSource = ref([]);
    const showInviteDialog = ref(false);
    const invitedEmail = ref("");

    const myEmail = computed(() => store.getters.userEmail);
    const optionTeams = computed(() => {
      return store.getters.teams.map((team) => ({
        label: team.name,
        value: team.uuid,
      }));
    });
    const selectedTeamUuid = computed({
      get: () => store.getters.teamUuid,
      set: (val) => {
        store.dispatch("fetchInfoUuid", val);
      },
    });

    const loadTeams = () => {
      blocked.value = true;

      TeamService.list()
        .then(({ data }) => {
          ownTeam.value = data.own_team;
          ownTeamName.value = data.own_team.name;
          store.dispatch("fetchTeamAndTeams", data);
          loadTeamMembers();
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const loadTeamMembers = () => {
      blocked.value = true;

      TeamService.list_members(ownTeam.value.uuid)
        .then(({ data }) => {
          dataSource.value = data.members;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const updateTeam = () => {
      blocked.value = true;

      TeamService.update_team(selectedTeamUuid.value)
        .then(({ data }) => {
          ownTeam.value = data.own_team;
          store.dispatch("fetchTeamAndTeams");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const updateInfo = () => {
      let updateMyName = myName.value.trim();
      if (updateMyName == "") {
        toast.add({
          severity: "warn",
          summary: "Warning! Missing account name",
          detail: "Please check account name",
          life: 3000,
        });
        return;
      }
      blocked.value = true;
      let data = {
        user: {
          name: updateMyName,
        },
      };
      UserInfoService.update(data)
        .then(({ data }) => {
          myName.value = data.name;
          store.dispatch("fetchUserFromBackend");
          loadTeamMembers();
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const updateOwnTeam = () => {
      let updateOwnTeamName = ownTeamName.value.trim();
      if (updateOwnTeamName == "") {
        toast.add({
          severity: "warn",
          summary: "Warning! Missing team name",
          detail: "Please check team name",
          life: 3000,
        });
        return;
      }
      blocked.value = true;
      let data = {
        own_team: {
          name: updateOwnTeamName,
        },
      };
      UserOwnTeamService.update(data)
        .then(({ data }) => {
          ownTeam.value = data.own_team;
          ownTeamName.value = data.own_team.name;
          store.dispatch("fetchTeamAndTeams");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          blocked.value = false;
        });
    };

    const handleShowInviteDialog = () => {
      showInviteDialog.value = true;
    };

    const sendInvitation = () => {
      if (invitedEmail.value.trim() === "") {
        handleHideInviteDialog();
        return;
      }

      TeamService.invite_member(ownTeam.value.uuid, invitedEmail.value)
        .then(() => {
          loadTeamMembers();

          toast.add({
            severity: "success",
            summary: "Invitation",
            detail: "Invite successfully",
            life: 3000,
          });
        })
        .catch((e) => {
          console.error(e);

          toast.add({
            severity: "error",
            summary: "Error",
            detail: "Invite fail, please check the user email",
            life: 3000,
          });
        })
        .finally(() => {
          handleHideInviteDialog();
        });
    };

    const handleHideInviteDialog = () => {
      showInviteDialog.value = false;
      invitedEmail.value = "";
    };

    const handleRemoveMember = (data) => {
      let email = data.email;

      confirm.require({
        message: `Are you sure you want to remove ${email} ?`,
        header: "Remove Member",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Remove",
        rejectLabel: "Cancel",
        accept: () => {
          TeamService.remove_member(ownTeam.value.uuid, email)
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Member removed",
                life: 3000,
              });

              loadTeamMembers();
            })
            .catch((e) => {
              console.error(e);

              let message = "Remove member fail";
              if (e.response.status === 400 || e.response.status === 401) {
                message = e.response.data?.error;
              }

              toast.add({
                severity: "error",
                summary: "Error",
                detail: `Error: ${message}`,
                life: 3000,
              });
            });
        },
      });
    };

    return {
      blocked,
      ownTeam,
      ownTeamName,
      selectedTeamUuid,
      optionTeams,
      updateTeam,
      updateOwnTeam,

      dataSource,

      myEmail,
      myName,
      updateInfo,

      showInviteDialog,
      invitedEmail,
      handleShowInviteDialog,
      handleHideInviteDialog,
      sendInvitation,
      handleRemoveMember,
    };
  },
});
</script>

<style lang="scss" scoped>
.current-team-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.table-header {
  display: flex;
  justify-content: flex-end;
}
</style>
