<template>
  <div>
    <Dropdown
      v-model="selectedOption"
      :options="options"
      :filter="true"
      @change="onChange"
      optionLabel="label"
      optionValue="value"
    >
    </Dropdown>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

import Dropdown from "primevue/dropdown";

export default defineComponent({
  components: {
    Dropdown,
  },
  props: ["modelValue", "options"],
  emits: ["update:modelValue", "change"],
  setup(props, { emit }) {
    const selectedOption = computed({
      get: () => {
        // has set value
        if (props.modelValue) {
          return props.modelValue;
        }

        // default option
        if (props.options && props.options.length > 0) {
          return props.options[0].value;
        }

        // default value
        return null;
      },
      set: (val) => {
        emit("update:modelValue", val);
      },
    });

    const onChange = (event) => {
      emit("change", event);
    };

    return {
      selectedOption,
      onChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.p-dropdown {
  width: 10rem;
  margin-right: 1rem;
}
</style>
